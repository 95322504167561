import * as React from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';

import { removeTrailingNonDigits } from '../utils/format';
import { Input, LogoContainer, SubmitButton, Title, VendahLogo } from '../components';
import { Container, InputsContainer, TitleContainer } from './styles';
import { useNavigate } from 'react-router-dom';
import { validadeVerificationCode } from '../client/vendah/orderPaymentService/draw-ticket';
import DrawTicketCustomerDetails from '../components/DrawTicketCustomerDetails';

const Form = styled.form``;

interface Props {
    phone: string;
    callback: string;
}

function DrawTicketVerificationCodePage({ phone, callback }: Props) {
    const [verificationCode, setVerificationCode] = React.useState('');
    const [invalidMessage, setInvalidMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<Error>();

    const navigate = useNavigate();

    const submitDisabled = verificationCode.length !== 6;

    async function submitForm(evt: any) {
        evt.preventDefault();
        if (submitDisabled) return;

        setLoading(true);
        setInvalidMessage('');

        try {
            const response = await validadeVerificationCode(phone, verificationCode, navigate);
            setLoading(false);

            gtag('event', 'draw_ticket_verification_code_submit');

            if (response.success) {
                if (response.customer) {
                    navigate(`/vale-compras-premiado/${callback}`);
                } else {
                    navigate('/vale-compras-premiado/registro', { state: { phone } });
                }
            } else {
                setInvalidMessage(response.error ?? 'Erro ao consultar o código de verificação');
            }
        } catch (error: any) {
            Sentry.captureException(error);
            setLoading(false);
            setError(new Error('Erro ao consultar o código de verificação!'));
        }
    }

    if (error) throw error;

    return (
        <Container>
            <LogoContainer>
                <VendahLogo />
            </LogoContainer>
            <DrawTicketCustomerDetails />
            <TitleContainer>
                <Title>Insira o código enviado para seu WhatsApp</Title>
            </TitleContainer>

            <Form onSubmit={submitForm}>
                <InputsContainer>
                    <Input
                        id="code"
                        value={verificationCode}
                        label="Código de verificação"
                        maxLength={6}
                        placeholder="999999"
                        onValueChange={text => {
                            setVerificationCode(removeTrailingNonDigits(text));
                        }}
                        invalidMessage={invalidMessage}
                    />
                </InputsContainer>
                <SubmitButton disabled={submitDisabled || loading}>
                    <Title>Avançar</Title>
                </SubmitButton>
            </Form>
        </Container>
    );
}

export default DrawTicketVerificationCodePage;
