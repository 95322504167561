import * as React from 'react';
import * as Sentry from '@sentry/react';

import { LogoContainer, Spinner, Title, VendahLogo } from '../components';
import { Container, Padding, TitleContainer } from './styles';
import { useAsyncEffect } from '../hook/useAsyncEffect';
import styled from 'styled-components';
import { DrawTickets, getDrawTickets } from '../client/vendah/orderPaymentService/draw-ticket';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ActionButton } from '../components/Button';
import DrawTicketCustomerDetails from '../components/DrawTicketCustomerDetails';
import { formatDateAsDDMMYYYY } from '../utils/format';

const VouchersContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    padding: 16px;
`;

const MessagesContainer = styled.div`
    display: flex;
    padding: 16px;
`;

const VoucherContainer = styled.div`
    align-items: center;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.12);
    padding: 0px 10px;
`;

const LineVoucherContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
`;

const drawTicketEndDate = new Date('2025-03-01T00:00:00-03:00');

function DrawTicketTicketsPage() {
    const [drawTickets, setDrawTickets] = React.useState<DrawTickets>();
    const [error, setError] = React.useState<Error>();
    const [isLoading, setLoading] = React.useState(false);

    const [searchParams] = useSearchParams();
    const paid = searchParams.get('paid');

    const navigate = useNavigate();

    useAsyncEffect(
        async () => {
            try {
                const response: DrawTickets = await getDrawTickets(navigate);

                gtag('event', 'draw_ticket_lucky_numbers', { lucky_number: response });
                return response;
            } catch (error) {
                Sentry.captureException(error);
                setError(new Error('Erro ao consultar os números da sorte!'));
            }
        },
        setDrawTickets,
        []
    );

    async function loadPreviousMonth() {
        if (!drawTickets) return;
        setLoading(true);

        try {
            const response = await getDrawTickets(navigate, drawTickets.previousMonth);
            setDrawTickets({ ...response, drawTickets: [...drawTickets.drawTickets, ...response.drawTickets] });
        } catch (error: any) {
            Sentry.captureException(error);
            setError(new Error('Erro ao consultar os números da sorte!'));
        }
        setLoading(false);
    }

    function navigateToShop() {
        navigate('/vale-compras-premiado/comprar');
    }

    if (error) throw error;

    if (!drawTickets) return <Spinner />;

    const endOfOperation = new Date() > drawTicketEndDate;

    return (
        <Container>
            <LogoContainer>
                <VendahLogo />
            </LogoContainer>
            <TitleContainer>
                <Title>Vale Compras Premiado</Title>
            </TitleContainer>
            <DrawTicketCustomerDetails />
            <MessagesContainer>
                {paid === '1' && <p>Pagamento efetuado com sucesso. Em breve seus números da sorte estarão aqui</p>}
                {paid !== '1' && drawTickets.drawTickets.length === 0 && (
                    <p>
                        Você não possui nenhum número da sorte nesse período. Clique em Carregar Mais para consultar os
                        meses anteriores
                    </p>
                )}
            </MessagesContainer>
            {drawTickets.drawTickets.length > 0 && (
                <>
                    <strong>Meus números da sorte</strong>
                    <p>Você está concorrendo</p>
                    <VouchersContainer>
                        {drawTickets.drawTickets.map((item, index) => (
                            <>
                                <VoucherContainer>
                                    <LineVoucherContainer>
                                        <span>
                                            Número ds sorte:{' '}
                                            <strong>#{item.monthLuckyNumber.split('-')[1].padStart(6, '0')}</strong>
                                        </span>
                                        <span>{formatDateAsDDMMYYYY(new Date(item.createdAt * 1000))}</span>
                                    </LineVoucherContainer>
                                    <LineVoucherContainer>Cupom: {item.coupon}</LineVoucherContainer>
                                </VoucherContainer>
                                <Padding />
                            </>
                        ))}
                    </VouchersContainer>
                </>
            )}
            {drawTickets.previousMonth && (
                <ActionButton color="blue" onPress={() => loadPreviousMonth()} disabled={isLoading}>
                    <Title>Carregar mais</Title>
                </ActionButton>
            )}
            {drawTickets.drawTickets.length < 10 && !endOfOperation && (
                <ActionButton onPress={() => navigateToShop()}>
                    <Title>Comprar</Title>
                </ActionButton>
            )}
        </Container>
    );
}

export default DrawTicketTicketsPage;
