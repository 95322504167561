import * as React from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';

import {
    formatAmountBrl,
    formatOnlyDigits,
    formatPhone,
    formatUsingMask,
    removeTrailingNonDigits,
} from '../utils/format';
import { roundTwoPlaces } from '../utils/number';
import { isCpfValid } from '../utils/validation/cpf';
import { isPhoneValid } from '../utils/validation/phone';
import { Input, LogoContainer, SubmitButton, Title, VendahLogo } from '../components';
import { Container, InputsContainer, Padding, SubTitleContainer, TitleContainer } from './styles';
import AddressForm, { Address } from '../components/AddressForm';
import { Button } from '../components/Button';
import { ALARM_COLOR, BLUE_PRIMARY_COLOR, COLOR_BLUE, INPUT_PLACEHOLDER_COLOR } from '../style';
import { useLocalStorageState, useLocalStorageStateObj } from '../utils/useLocalStorage';
import { useAsyncEffect } from '../hook/useAsyncEffect';
import { getShippingInfo, ShippingInfoResponse } from '../client/vendah/orderPaymentService/shipping-info';
import FreeShippingCard from '../components/FreeShippingCard';
import { useParams } from 'react-router-dom';
import {
    CheckoutData,
    LineItemValidateRequest,
    ResellerCheckoutData,
    validateCheckout,
    Variant,
} from '../client/vendah/orderPaymentService/validate-checkout';
import {
    CouponResponse,
    findConectahReseller,
    getResellerByPhone,
    getResellerCoupon,
    ResellerPhoneResponse,
    ResellerReponse,
} from '../client/vendah/orderPaymentService/reseller';
import {
    createOrder,
    CreateOrderRequest,
    CreateOrderResponse,
    LineItem,
} from '../client/vendah/orderPaymentService/orders';
import Cancel from '../components/icons/cancel';
import { computeCartDiscount } from '../utils/discount';

const ResellerNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;

    padding: 16px;
`;

const ShippingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 16px;
    line-height: 14px;

    text-align: left;
`;

const PriceContainer = styled.div`
    white-space: nowrap;
`;

const LineItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const LineItemTitleContainer = styled.div`
    display: flex;
    text-align: left;
    align-items: center;
    img {
        width: 32px;
        height: 32px;

        margin-right: 16px;
    }
`;

const ShippingOptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 16px;
`;

const CostContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 6px 16px;
`;

const DiscountContainer = styled.div`
    display: flex;
    color: ${BLUE_PRIMARY_COLOR};
    flex-direction: row;
    justify-content: space-between;
    padding: 6px 16px;
`;

const LineItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    padding: 0px 16px;
`;

const TotalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-weight: 700;
    font-size: 16px;

    padding: 6px 16px;
`;

const PriceSummaryContainer = styled.div`
    padding-top: 10px;
    margin-top: 10px;
    background: linear-gradient(0deg, #f9f9f9, #f9f9f9), #ffffff;
`;

const ShippingDelayWarningContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;

    font-size: 12px;
    padding: 0px 16px 6px 16px;
`;

const StyledInput = styled.input`
    color: ${COLOR_BLUE};
    font-family: 'Poppins';
    font-style: normal;
    font-size: 14px;
    include-font-padding: false;

    border-color: ${COLOR_BLUE};
    ::placeholder {
        font-size: 14px;
        color: ${INPUT_PLACEHOLDER_COLOR};
    }
`;

const StyledCouponLineView = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 6px 16px;
    align-items: center;
`;

const StyledCouponView = styled.div`
    display: flex;
    flex: 2;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const DiscountText = styled.div`
    color: ${COLOR_BLUE};
`;

const CancelButtonView = styled.div``;

const StyledErrorMessageText = styled.div`
    color: ${ALARM_COLOR};
`;

const Form = styled.form``;

function CheckoutPage() {
    const [submitInProgress, setSubmitInProgress] = React.useState(false);
    const [error, setError] = React.useState<Error>();
    const [shipToReseller, setShipToReseller] = React.useState(true);
    const [name, setName] = useLocalStorageState('checkout.name');
    const [cpf, setCpf] = useLocalStorageState('checkout.cpf');
    const [phone, setPhone] = useLocalStorageState('checkout.phone');
    const [resellerPhone, setResellerPhone] = React.useState('');
    const [address, setAddress] = useLocalStorageStateObj<Address>('checkout.address', {
        postalCode: '',
        streetAddress: '',
        streetNumber: '',
        complement: '',
        reference: '',
        neighborhood: '',
        city: '',
        stateCode: '',
    });
    const [invalidAddress, setInvalidAddress] = React.useState(false);
    const [postalCodeInvalidMessage, setPostalCodeInvalidMessage] = React.useState<string>();
    const [resellerPhoneInvalidMessage, setResellerPhoneInvalidMessage] = React.useState<string>();
    const [coupon, setCoupon] = React.useState<CouponResponse>();
    const [couponCode, setCouponCode] = React.useState('');
    const [couponInputMode, setCouponInputMode] = React.useState(false);
    const [couponErrorMessage, setCouponErrorMessage] = React.useState('');
    const [couponLoading, setCouponLoading] = React.useState(false);

    const nameInvalid = React.useMemo(() => name.split(' ').filter(name => name.trim().length > 0).length < 2, [name]);
    const customerTaxIdInvalid = React.useMemo(() => !isCpfValid(cpf), [cpf]);
    const phoneInvalid = React.useMemo(() => !isPhoneValid(phone), [phone]);
    const resellerPhoneInvalid = React.useMemo(() => !isPhoneValid(resellerPhone), [resellerPhone]);
    const [loadingShipping, setLoadingShipping] = React.useState(false);
    const [shippingInfo, setShippingInfo] = React.useState<ShippingInfoResponse>();
    const [reseller, setReseller] = React.useState<ResellerCheckoutData | undefined>();
    const [lineItems, setLineItems] = React.useState<Variant[]>();
    const [alreadyHasReseller, setAlreadyHasReseller] = React.useState(false);
    const [isConectah, setConectah] = React.useState(false);
    const postalCode = address.postalCode;

    //const shipments = cartItems.reduce((map, c) => map.set(c.vendor.id, [...(map.get(c.vendor.id) || []), c]), new Map());
    //const vendorsShipping = getVendorsShippingFee(shipments, shippingInfo);

    const { resellerId, items } = useParams();

    React.useEffect(() => {
        if (isConectah) setShipToReseller(false);
        if (reseller && !reseller.can_ship_to_reseller) setShipToReseller(false);
    }, [isConectah, reseller, reseller?.can_ship_to_reseller]);

    const shipmentPromise = getShipmentWorkdays();

    function getShipmentWorkdays(): string | undefined {
        if (!shippingInfo) return undefined;

        if (shippingInfo.vendors.length !== 1) {
            setError(new Error('Erro ao calcular o frete'));
            return undefined;
        }
        return shippingInfo.vendors[0].promise;
    }

    console.log('invalidAddress', invalidAddress);

    useAsyncEffect(
        async () => {
            if (!items) return undefined;

            const lineItems: LineItemValidateRequest[] = [];
            for (const itemStr of items.split(',')) {
                const [variantId, quantity] = itemStr.split(':');
                lineItems.push({
                    variant_id: variantId,
                    quantity: parseInt(quantity || '1'),
                });
            }

            try {
                const response: CheckoutData = await validateCheckout({
                    reseller_id: resellerId,
                    line_items: lineItems,
                });

                gtag('event', 'begin_checkout', { variants: response });
                return response;
            } catch (error) {
                setError(new Error('Erro ao procurar produtos!'));
            }
        },
        checkoutData => {
            if (!checkoutData) return;
            setReseller(checkoutData.reseller);
            setLineItems(checkoutData.line_items);
        },
        []
    );

    useAsyncEffect(
        async () => {
            setPostalCodeInvalidMessage(undefined);

            const clearPostalCode = formatOnlyDigits(postalCode);
            if (!shipToReseller) {
                if (!postalCode) return undefined;

                if (clearPostalCode.length !== 8) {
                    return undefined;
                }
            }
            if (!reseller) return undefined;
            if (!lineItems) return undefined;

            setLoadingShipping(true);

            console.log('calling getShippingInfo', postalCode);
            setShippingInfo(undefined);
            try {
                const shippingInfo = await getShippingInfo({
                    reseller_id: reseller.id,
                    postal_code: shipToReseller ? undefined : postalCode,
                    auto_checkout: true,
                    items: lineItems.map(item => ({
                        quantity: item.quantity,
                        variant_id: item.id,
                    })),
                });

                if (!shippingInfo.success) {
                    const errorMessage = shippingInfo.error ?? 'Erro ao calcular o Frete';
                    setPostalCodeInvalidMessage(errorMessage);
                    return undefined; // must not set the unsuccessful shippingInfo (its type assumes successful)
                }

                if (isConectah && !isSPPostalCode(clearPostalCode)) {
                    setPostalCodeInvalidMessage('Não é possível entregar para esse endereço'); // Conectah v1 MVP restriction
                }

                return shippingInfo;
            } catch (error) {
                Sentry.captureException(error);
                setError(new Error('Erro ao calcular o Frete'));
                return undefined;
            } finally {
                setLoadingShipping(false);
            }
        },
        setShippingInfo,
        [postalCode, shipToReseller, reseller, isConectah, lineItems]
    );

    useAsyncEffect(
        async () => {
            if (!resellerPhone) return;

            const clearResellerPhone = formatOnlyDigits(resellerPhone);
            if (clearResellerPhone.length !== 11) {
                if (clearResellerPhone.length > 0) {
                    setResellerPhoneInvalidMessage('O Celular precisa ter 11 dígitos');
                }
                return;
            }
            setResellerPhoneInvalidMessage(undefined);

            try {
                const response: ResellerPhoneResponse = await getResellerByPhone(clearResellerPhone);

                if (response.success && response.availability === 'IN_USE') {
                    console.log('Found reseller by phone', response);
                    return {
                        id: response.reseller_id,
                        first_name: response.first_name || '',
                        custom_name: response.custom_name,
                        can_ship_to_reseller: response.can_ship_to_reseller,
                    };
                }
                setResellerPhoneInvalidMessage('Revendedora não encontrada com esse celular');
                return undefined;
            } catch (error) {
                Sentry.captureException(error);
                setError(new Error('Erro ao encontrar a revendedora'));
                return undefined;
            }
        },
        setReseller,
        [resellerPhone]
    );

    function isSPPostalCode(clearPostalCode: string) {
        const cleanPostalCode = parseInt(clearPostalCode);
        return cleanPostalCode >= 1000000 && cleanPostalCode <= 19999999;
    }

    function getShippingFee(totalSuggestedPrice: number, shippingInfo?: ShippingInfoResponse) {
        if (!shippingInfo) return { fee: undefined, priceToFreeShipping: 0 };

        const vendors = shippingInfo.vendors;
        if (vendors.length !== 1) {
            setError(new Error('Erro ao calcular o frete'));
            return { fee: undefined, priceToFreeShipping: 0 };
        }

        return { fee: vendors[0].fee, priceToFreeShipping: vendors[0].price_to_free_shipping };
    }

    function splitName(name: string): [string, string] {
        const index = name.indexOf(' ');
        return [name.slice(0, index).trim(), name.slice(index + 1, name.length).trim()];
    }

    function convertLineItems(cartItems: Variant[]): LineItem[] {
        return cartItems.map(
            (cartItem): LineItem => ({
                variant_id: parseInt(cartItem.id),
                quantity: cartItem.quantity,
                unit_price: parseFloat(cartItem.price),
                unit_discount: 0,
            })
        );
    }

    async function findReseller() {
        try {
            const response: ResellerReponse = await findConectahReseller();

            console.log('findReseller response', response);
            setConectah(true);
            setShipToReseller(false);
            setReseller({
                id: response.reseller_id,
                first_name: response.first_name || '',
                custom_name: response.custom_name,
                can_ship_to_reseller: response.can_ship_to_reseller,
            });
        } catch (error) {
            Sentry.captureException(error);
            setError(new Error('Erro ao procurar um revendedora'));
            return undefined;
        }
    }

    async function submitForm(evt: any) {
        evt.preventDefault();
        if (!submitEnabled) return;
        if (!reseller) return;
        if (!lineItems) return;

        if (submitInProgress) {
            console.log('Ignoring double submit');
            return;
        }
        setSubmitInProgress(true);

        const [firstName, lastName] = splitName(name);

        const request: CreateOrderRequest = {
            reseller_id: reseller.id,
            need_to_set_address: false,
            customer_first_name: firstName,
            customer_last_name: lastName,
            customer_phone: phone,
            ship_to_reseller: shipToReseller,
            line_items: convertLineItems(lineItems),
            shipping_fee: shipping.fee,
            auto_checkout: true,
            tags: isConectah ? 'conectah' : undefined,
            coupon: coupon
                ? {
                      code: coupon.code,
                      amount: couponDiscount,
                  }
                : undefined,
            discount: cartDiscount.amount > 0 ? cartDiscount : undefined,
            customer_tax_id: cpf,
        };

        if (!shipToReseller) {
            request.shipping_address = {
                postal_code: address.postalCode,
                street_address: address.streetAddress,
                street_number: address.streetNumber,
                complement: address.complement,
                reference: address.reference,
                neighborhood: address.neighborhood,
                city: address.city,
                state_code: address.stateCode,
                phone: phone,
            };
        }

        try {
            console.log('create order request', request);
            const response: CreateOrderResponse = await createOrder(request);

            console.log('create order response', response);
            if (response.success) {
                window.location.replace(
                    `https://vendah.com.br/pages/pedido?id=${response.hash}&r=${reseller.custom_name}`
                );
            } else {
                setError(new Error(response.error || 'Erro ao processar seu pedido'));
            }
        } catch (error: any) {
            Sentry.captureException(error);
            setError(new Error('Erro ao processar seu pedido'));
        }
    }

    async function loadCoupon(evt: any): Promise<void> {
        evt.preventDefault();

        if (!reseller) return;

        setCouponInputMode(false);
        const code = couponCode.trim();
        if (!code) {
            setCoupon(undefined);
            return;
        }

        try {
            setCouponErrorMessage('');
            setCouponLoading(true);
            const response: CouponResponse = await getResellerCoupon(reseller.id, code);

            gtag('event', response.success ? 'coupon_success' : 'coupon_unsuccess', { coupon: response });
            if (response.success) {
                if (response.min_total_price && subtotal < response.min_total_price) {
                    setCouponErrorMessage(
                        `Valor mínimo de ${formatAmountBrl(
                            response.min_total_price
                        )} para ativar o cupom não foi atingido`
                    );
                    setCoupon(undefined);
                } else {
                    setCoupon(response);
                }
            } else {
                if (response.status_code < 500) {
                    setCouponErrorMessage(response.error || 'Erro ao consultar o prêmio');
                } else {
                    setError(new Error(response.error || 'Erro ao consultar o prêmio'));
                }
            }
            setCouponLoading(false);
        } catch (error: any) {
            setCouponLoading(false);
            Sentry.captureException(error);
            setError(new Error(error.error || 'Erro ao consultar o prêmio'));
        }
    }

    function removeCoupon() {
        setCouponCode('');
        setCoupon(undefined);
        setCouponErrorMessage('');
        setCouponInputMode(false);
    }

    if (!lineItems) return null;

    function priceFormat(value: number | undefined): string {
        if (value === undefined) return 'A calcular';

        if (value === 0) return 'Grátis';

        return formatAmountBrl(value);
    }

    const subtotal = roundTwoPlaces(
        lineItems.map(item => parseFloat(item.price) * item.quantity).reduce((sum, val) => sum + val, 0)
    );
    const shipping = getShippingFee(subtotal, shippingInfo);
    const cartDiscount = computeCartDiscount(subtotal, coupon);

    const couponDiscount = coupon?.amount ?? 0;
    const total = subtotal + (shipping.fee || 0) - couponDiscount - cartDiscount.amount;
    const submitEnabled =
        reseller &&
        name &&
        lineItems &&
        !submitInProgress &&
        !nameInvalid &&
        !customerTaxIdInvalid &&
        !phoneInvalid &&
        (shipToReseller || !invalidAddress) &&
        shipping.fee !== undefined;

    console.log('reseller', reseller);

    if (error) throw error;

    return (
        <Container>
            <LogoContainer>
                <VendahLogo />
            </LogoContainer>
            <TitleContainer>
                <Title>Resumo do pedido</Title>
            </TitleContainer>
            <SubTitleContainer>
                <Title>Seus Dados</Title>
            </SubTitleContainer>

            <InputsContainer>
                <Input
                    id="name"
                    value={name}
                    label="Nome Completo"
                    onValueChange={setName}
                    placeholder="Joana da Silva"
                    invalidMessage={nameInvalid ? 'Preencher o nome completo' : undefined}
                />
                <Input
                    id="cpf"
                    value={cpf}
                    label="CPF"
                    placeholder="000.000.000-00"
                    onValueChange={text => setCpf(removeTrailingNonDigits(formatUsingMask(text, '999.999.999-99')))}
                    invalidMessage={customerTaxIdInvalid ? 'CPF inválido' : undefined}
                />
                <Input
                    id="phone"
                    value={phone}
                    label="Celular"
                    placeholder="(11) 99999-9999"
                    onValueChange={text => {
                        setPhone(removeTrailingNonDigits(formatPhone(text)));
                    }}
                    invalidMessage={phoneInvalid ? 'Celular inválido' : undefined}
                />
            </InputsContainer>
            {!isConectah && reseller?.can_ship_to_reseller && (
                <>
                    <SubTitleContainer>
                        <Title>Como deseja receber?</Title>
                    </SubTitleContainer>
                    <ShippingOptionsContainer>
                        <Button selected={shipToReseller} onPress={() => setShipToReseller(true)}>
                            <div>
                                <strong>Retirar</strong> com{' '}
                                {reseller ? reseller.first_name : 'Revendedora'}
                            </div>
                        </Button>
                        <Button selected={!shipToReseller} onPress={() => setShipToReseller(false)}>
                            <div>
                                <strong>Receber</strong> no meu endereço
                            </div>
                        </Button>
                    </ShippingOptionsContainer>
                </>
            )}
            {reseller && (
                <ResellerNameContainer>
                    <strong>Vendido por</strong>
                    <p>{reseller.first_name}</p>
                </ResellerNameContainer>
            )}
            {!reseller && (
                <>
                    <ResellerNameContainer>
                        <strong>Vendido por</strong>
                        <p>Nenhuma revendedora</p>
                    </ResellerNameContainer>
                    <ShippingOptionsContainer>
                        <Button selected={true} onPress={() => setAlreadyHasReseller(true)}>
                            <div>
                                Já <strong>tenho</strong> uma revendedora
                            </div>
                        </Button>
                        <Button selected={true} onPress={findReseller}>
                            <div>
                                <strong>Preciso</strong> de uma revendedora
                            </div>
                        </Button>
                    </ShippingOptionsContainer>
                    {alreadyHasReseller && (
                        <InputsContainer>
                            <Input
                                id="phone"
                                value={resellerPhone}
                                label="Celular da Revendedora"
                                placeholder="(11) 99999-9999"
                                onValueChange={text => {
                                    setResellerPhone(removeTrailingNonDigits(formatPhone(text)));
                                }}
                                invalidMessage={resellerPhoneInvalid ? 'Celular inválido' : resellerPhoneInvalidMessage}
                            />
                        </InputsContainer>
                    )}
                </>
            )}
            {!shipToReseller && (
                <>
                    <SubTitleContainer>
                        <Title>Endereço para entrega</Title>
                    </SubTitleContainer>
                    <AddressForm
                        address={address}
                        onChangeAddress={(fieldsToUpdate: Partial<Address>) => {
                            if (fieldsToUpdate.postalCode) {
                                fieldsToUpdate.postalCode = removeTrailingNonDigits(
                                    formatUsingMask(fieldsToUpdate.postalCode, '99999-999')
                                );
                            }
                            setAddress(prevState => ({ ...prevState, ...fieldsToUpdate }));
                        }}
                        onChangeInvalid={setInvalidAddress}
                        invalidMessage={postalCodeInvalidMessage}
                    />
                </>
            )}
            <SubTitleContainer>
                <Title>Detalhes do pedido</Title>
            </SubTitleContainer>
            <LineItemsContainer>
                {lineItems.map((item, index) => (
                    <LineItemContainer key={index}>
                        <LineItemTitleContainer>
                            <img src={item.image_url} alt="" />
                            <p>
                                {item.quantity} × {item.title}
                            </p>
                        </LineItemTitleContainer>
                        <PriceContainer>{formatAmountBrl(parseFloat(item.price) * item.quantity)}</PriceContainer>
                    </LineItemContainer>
                ))}
            </LineItemsContainer>
            <FreeShippingCard price={subtotal} priceToFreeShipping={shipping.priceToFreeShipping} />
            <PriceSummaryContainer>
                <CostContainer>
                    <div>Subtotal</div>
                    <div>{formatAmountBrl(subtotal)}</div>
                </CostContainer>
                <CostContainer>
                    <div>
                        Frete
                        {shippingInfo && shipmentPromise && ` (${shipmentPromise})`}
                    </div>
                    <div>{loadingShipping ? 'Calculando...' : priceFormat(shipping.fee)}</div>
                </CostContainer>
                {shippingInfo?.has_delay && (
                    <ShippingDelayWarningContainer>
                        <div>Nossos prazos estão temporariamente acima do normal</div>
                    </ShippingDelayWarningContainer>
                )}
                {!couponCode && !couponInputMode && (
                    <DiscountContainer onClick={() => setCouponInputMode(true)}>
                        Tem prêmio para resgatar?
                    </DiscountContainer>
                )}
                {couponInputMode && (
                    <StyledCouponLineView>
                        <StyledCouponView>
                            <Form onSubmit={loadCoupon}>
                                <StyledInput
                                    id="couponCode"
                                    type="text"
                                    value={couponCode}
                                    onChange={e => setCouponCode(e.target.value)}
                                    placeholder="Digite seu prêmio"
                                    disabled={false}
                                />
                            </Form>
                            <Padding />
                            <CancelButtonView onClick={removeCoupon}>
                                <Cancel />
                            </CancelButtonView>
                        </StyledCouponView>
                        <DiscountText style={{ top: 4 }} onClick={loadCoupon}>
                            Aplicar
                        </DiscountText>
                    </StyledCouponLineView>
                )}
                {couponCode && !couponInputMode && (
                    <CostContainer>
                        <StyledCouponView>
                            <DiscountText>{couponCode.toUpperCase()}</DiscountText>
                            <Padding />
                            <CancelButtonView onClick={removeCoupon}>
                                <Cancel />
                            </CancelButtonView>
                        </StyledCouponView>
                        {!couponLoading && <DiscountText>- {formatAmountBrl(couponDiscount)}</DiscountText>}
                    </CostContainer>
                )}
                {couponErrorMessage && couponErrorMessage !== '' && (
                    <StyledErrorMessageText>{couponErrorMessage}</StyledErrorMessageText>
                )}
                {cartDiscount.amount > 0 && (
                    <DiscountContainer>
                        <div>{cartDiscount.name}</div>
                        <div>- {formatAmountBrl(cartDiscount.amount)}</div>
                    </DiscountContainer>
                )}
                <TotalContainer>
                    <div>Total</div>
                    <div>{formatAmountBrl(total)}</div>
                </TotalContainer>
                <Form onSubmit={submitForm}>
                    <SubmitButton disabled={!submitEnabled}>
                        <Title>{submitInProgress ? 'Carregando...' : 'Criar pedido'}</Title>
                    </SubmitButton>
                </Form>
            </PriceSummaryContainer>
            {shipToReseller && shipmentPromise !== undefined && (
                <ShippingContainer>
                    <strong>Forma de entrega</strong>
                    <p>
                        Retire com {reseller ? reseller.first_name : 'Revendedora'} ({shipmentPromise})
                    </p>
                </ShippingContainer>
            )}
        </Container>
    );
}

export default CheckoutPage;
