import * as React from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';

import { formatUsingMask, removeTrailingNonDigits } from '../utils/format';
import { LogoContainer, SubmitButton, Title, VendahLogo } from '../components';
import { Container, Padding, TitleContainer } from './styles';
import AddressForm, { Address } from '../components/AddressForm';
import { useNavigate } from 'react-router-dom';
import { StoreResellerRequest } from '../container/Registration.container';
import { useLocalStorageStateObj } from '../utils/useLocalStorage';
import { executeRequest } from '../client/utils';
import { OPS_API_URL } from '../const';
import urlJoin from 'url-join';

const Form = styled.form``;

interface Props {
    storeReseller: Pick<StoreResellerRequest, 'email' | 'first_name' | 'last_name' | 'phone' | 'cpf'>;
    resellerId?: string;
}

function RegistrationAddressPage({ storeReseller, resellerId }: Props) {
    const [address, setAddress] = useLocalStorageStateObj<Address>('register.address', {
        postalCode: '',
        streetAddress: '',
        streetNumber: '',
        complement: '',
        reference: '',
        neighborhood: '',
        city: '',
        stateCode: '',
    });
    const [invalidAddress, setInvalidAddress] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<Error>();

    const navigate = useNavigate();
    const submitEnabled = !invalidAddress;

    async function submitForm(evt: any) {
        evt.preventDefault();
        if (!submitEnabled) return;

        const data: StoreResellerRequest = {
            ...storeReseller,
            address: {
                postal_code: address.postalCode,
                street_address: address.streetAddress,
                street_number: address.streetNumber,
                complement: address.complement,
                reference: address.reference,
                neighborhood: address.neighborhood,
                city: address.city,
                state_code: address.stateCode,
            },
            extra_fields: {},
            vendah_source: 'web',
        };

        try {
            setLoading(true);
            const res = await executeRequest(
                urlJoin(OPS_API_URL, 'store', 'reseller', resellerId ?? ''),
                {
                    method: resellerId ? 'PUT' : 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                },
                { ignoreStatus: true },
            );
            setLoading(false);

            gtag('event', 'sign_up');

            if (!res.success) {
                navigate('/mensagem', { state: { messageType: 'INFO', infoMessage: res.error } });
                return;
            }

            switch (res.state) {
                case 'DISABLED':
                case 'INVITED':
                    navigate('/mensagem', { state: { messageType: res.state } });
                    break;
                default:
                    navigate('/mensagem', {
                        state: {
                            messageType: 'INFO',
                            infoMessage: `Cadastro com status inválido: ${res.state}`,
                        },
                    });
                    break;
            }
        } catch (error: any) {
            Sentry.captureException(error);
            setLoading(false);
            setError(error);
        }
    }

    if (error) throw error;

    return (
        <Container>
            <LogoContainer>
                <VendahLogo />
            </LogoContainer>
            <TitleContainer>
                <Title>Endereço</Title>
            </TitleContainer>

            <Form onSubmit={submitForm}>
                <AddressForm
                    address={address}
                    onChangeAddress={(fieldsToUpdate: Partial<Address>) => {
                        if (fieldsToUpdate.postalCode) {
                            fieldsToUpdate.postalCode = removeTrailingNonDigits(
                                formatUsingMask(fieldsToUpdate.postalCode, '99999-999')
                            );
                        }
                        setAddress(prevState => ({ ...prevState, ...fieldsToUpdate }));
                    }}
                    onChangeInvalid={setInvalidAddress}
                />
                <Padding />
                <SubmitButton disabled={!submitEnabled || isLoading}>
                    <Title>Cadastrar</Title>
                </SubmitButton>
            </Form>
        </Container>
    );
}

export default RegistrationAddressPage;
