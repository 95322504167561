import * as React from 'react';
import * as Sentry from '@sentry/react';

import styled from 'styled-components';
import { resolveFromPostalCode } from '../client/address';
import { useAsyncEffect } from '../hook/useAsyncEffect';
import Input from './Input';
import { InputsContainer } from '../screens/styles';

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`;

const LineContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const StreetNumberContainer = styled.div`
    width: 30%;
`;

const ComplementContainer = styled.div`
    width: 70%;
`;

const stateCodes = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
];

export type Address = {
    postalCode: string;
    streetAddress: string;
    streetNumber: string;
    complement: string;
    reference: string;
    neighborhood: string;
    city: string;
    stateCode: string;
};

interface AddressFormProps extends React.PropsWithChildren {
    address: Address;
    onChangeAddress: (fieldsToUpdate: Partial<Address>) => void;
    onChangeInvalid: (isInvalid: boolean) => void;
    invalidMessage?: string;
}

function AddressForm({ address, onChangeAddress, onChangeInvalid, invalidMessage }: AddressFormProps) {
    const [postalCodeInvalidMessage, setPostalCodeInvalidMessage] = React.useState<string>();
    const [isStreetAndNeighborhoodEditable, setStreetAndNeighborhoodEditable] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        const valid =
            address.streetAddress &&
            address.streetNumber &&
            address.neighborhood &&
            address.city &&
            stateCodes.includes(address.stateCode.toUpperCase()) &&
            address.postalCode.length === 9 &&
            !invalidMessage;
        onChangeInvalid(!valid);
    }, [address, onChangeInvalid, invalidMessage]);

    useAsyncEffect(
        async () => {
            const postalCode = address.postalCode;
            if (!postalCode) return;

            const clearPostalCode = postalCode.replace(/[\s.-]+/, '');
            if (clearPostalCode.length !== 8) {
                if (clearPostalCode.length > 0) {
                    setPostalCodeInvalidMessage('O CEP precisa ter 8 dígitos');
                }
                return;
            }
            setPostalCodeInvalidMessage(undefined);

            console.log('calling resolveFromPostalCode', postalCode);
            onChangeAddress({ streetAddress: '' });
            setIsLoading(true);
            try {
                return await resolveFromPostalCode(clearPostalCode);
            } catch (error) {
                Sentry.captureException(error);
                onChangeAddress({ streetAddress: '' });
                setPostalCodeInvalidMessage('Não foi possível identificar este CEP');
                return undefined;
            } finally {
                setIsLoading(false);
            }
        },
        address => {
            if (!address) return;
            setStreetAndNeighborhoodEditable(!address.streetAddress || !address.neighborhood);
            onChangeAddress(address);
        },
        [address.postalCode]
    );

    return (
        <Container>
            <InputsContainer>
                <Input
                    id="postalCode"
                    value={address.postalCode}
                    onValueChange={(text: string) => onChangeAddress({ postalCode: text })}
                    label="CEP"
                    placeholder="00000-000"
                    invalidMessage={postalCodeInvalidMessage ?? invalidMessage}
                    maxLength={9}
                />
                <Input
                    id="streedAddress"
                    value={address.streetAddress}
                    onValueChange={(text: string) => onChangeAddress({ streetAddress: text })}
                    label="Logradouro"
                    placeholder={isLoading ? 'Carregando...' : 'Avenida, rua, alameda, etc.'}
                    disabled={!postalCodeInvalidMessage && !isStreetAndNeighborhoodEditable}
                />
                <LineContainer>
                    <StreetNumberContainer>
                        <Input
                            id="streetNumber"
                            value={address.streetNumber}
                            onValueChange={(text: string) => onChangeAddress({ streetNumber: text })}
                            label="Número"
                            placeholder="00000"
                        />
                    </StreetNumberContainer>
                    <ComplementContainer>
                        <Input
                            id="complement"
                            value={address.complement}
                            onValueChange={(text: string) => onChangeAddress({ complement: text })}
                            label="Complemento"
                            placeholder="Apto, bloco, etc."
                        />
                    </ComplementContainer>
                </LineContainer>
                <Input
                    id="reference"
                    value={address.reference}
                    onValueChange={(text: string) => onChangeAddress({ reference: text })}
                    label="Referência"
                    placeholder="Próximo ao..."
                />
                <Input
                    id="neighborhood"
                    value={address.neighborhood}
                    onValueChange={(text: string) => onChangeAddress({ neighborhood: text })}
                    label="Bairro"
                    placeholder="Vila Verde"
                    disabled={!postalCodeInvalidMessage && !isStreetAndNeighborhoodEditable}
                />
                <LineContainer>
                    <ComplementContainer>
                        <Input
                            id="city"
                            value={address.city}
                            onValueChange={(text: string) => onChangeAddress({ city: text })}
                            label="Cidade"
                            placeholder="São Paulo"
                            disabled={!postalCodeInvalidMessage}
                        />
                    </ComplementContainer>
                    <StreetNumberContainer>
                        <Input
                            id="state"
                            value={address.stateCode}
                            onValueChange={(text: string) => onChangeAddress({ stateCode: text })}
                            label="UF"
                            placeholder="SP"
                            maxLength={2}
                            disabled={!postalCodeInvalidMessage}
                        />
                    </StreetNumberContainer>
                </LineContainer>
            </InputsContainer>
        </Container>
    );
}

export default AddressForm;
