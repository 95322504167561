import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import RegistrationEmailPage from '../screens/RegistrationEmail.page';
import RegistrationPersonalDataPage from '../screens/RegistrationPersonalData.page';
import RegistrationAddressPage from '../screens/RegistrationAddress.page';
import * as Sentry from '@sentry/react';

type AddressRequest = {
    postal_code: string;
    street_address: string;
    street_number: string;
    complement: string;
    reference: string;
    neighborhood: string;
    city: string;
    state_code: string;
};

export type StoreResellerRequest = {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    extra_fields: { [key: string]: string[] };
    cpf: string;
    address: AddressRequest;
    vendah_source?: string;
    operating_system?: 'ios' | 'android';
};

function startRegistration(storeReseller: Partial<StoreResellerRequest>, searchParams: URLSearchParams) {
    const extraFields = (storeReseller.extra_fields ??= {});
    searchParams.forEach((value, key) => {
        if (!extraFields[key]) {
            extraFields[key] = [];
        }
        extraFields[key].push(value);
    });

    return <RegistrationEmailPage storeReseller={storeReseller} />;
}

function RegistrationContainer(): JSX.Element | null {
    const [searchParams] = useSearchParams();
    const { step } = useParams();
    const { state } = useLocation();

    if (!state || !state.storeReseller) return startRegistration({}, searchParams);
    const storeReseller: Partial<StoreResellerRequest> = state.storeReseller;

    if (!hasFields(storeReseller, ['email'])) {
        return startRegistration(storeReseller, searchParams);
    }
    if (step === 'dados') {
        return <RegistrationPersonalDataPage storeReseller={storeReseller} resellerId={state?.resellerId} />;
    }

    if (!hasFields(storeReseller, ['first_name', 'last_name', 'phone', 'cpf'])) {
        return startRegistration(storeReseller, searchParams);
    }
    if (step === 'endereco') {
        return <RegistrationAddressPage storeReseller={storeReseller} resellerId={state.resellerId} />;
    }

    //if (step === 'email') {
    return startRegistration(storeReseller, searchParams);
}

function hasFields<K extends keyof T, T>(data: Partial<T>, fields: K[]): data is Pick<T, K> & Partial<T> {
    for (const field of fields) {
        if (!data[field]) {
            console.log('missing expected field during registration', field);
            Sentry.captureMessage(`missing expected field ${field.toString()} during registration`);
            return false;
        }
    }
    return true;
}

export default RegistrationContainer;
