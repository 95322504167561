import { Discount } from '../client/vendah/orderPaymentService/orders';
import { CouponResponse } from '../client/vendah/orderPaymentService/reseller';
import { roundTwoPlaces } from './number';

const startMegaDiscount = new Date('2025-03-17T00:00:00-03:00');
const endMegaDiscount = new Date('2025-03-27T00:00:00-03:00');

export function computeCartDiscount(totalSuggestedPrice: number, coupon?: CouponResponse): Discount {
    const emptyReturn = { amount: 0, name: '' };
    const now = new Date();
    if (now < startMegaDiscount || now > endMegaDiscount) return emptyReturn;
    if (coupon) return emptyReturn;

    if (totalSuggestedPrice >= 1000)
        return { amount: roundTwoPlaces((totalSuggestedPrice * 20) / 100), name: 'Mega Descontão 20%' };
    if (totalSuggestedPrice >= 500)
        return { amount: roundTwoPlaces((totalSuggestedPrice * 10) / 100), name: 'Mega Descontão 10%' };
    return { amount: 0, name: '' };
}
